@import url("https://fonts.googleapis.com/css2?family=Ubuntu:ital,wght@0,300;0,400;0,500;0,700;1,300;1,400;1,500;1,700&display=swap");

*,
*::before,
*::after {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}
* {
  scroll-behavior: smooth;
}
:root {
  --bg: #282c34;
  --nav-bg: rgba(0, 0, 0, 0.7);
  --bg-sec: #2c2c2c;
  --bg-sec-shadow: 8px 8px 16px #121212, -8px -8px 16px #464646;
  --color-pri: #fff;
  --color-sec: #61dafb;
  --color-th: #ffc942;
  --color-gray: #c2c2c2;
  --color-gray-deep: #9d9d9d;
  --black: #000;
}
*::-webkit-scrollbar {
  width: 5px;
  height: 5px;
  border-radius: 4px;
  color: var(--color-th);
}
*::-webkit-scrollbar-thumb {
  border: 5px solid var(--color-th);
  border-radius: 4px;
  background: transparent;
}

body {
  /* background-color: var(--bg); */
  background-color: var(--bg-sec);
  color: var(--color-pri);
  margin: 0;
  font-family: "Ubuntu", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
